import {popupWindow} from './utils';

export function shareLinkToFacebook(shareUrl, pushData = null, popupOptions = null) {
  if (pushData) {
    window.dataLayer.push(pushData);
  }
  const url = `https://www.facebook.com/sharer.php?u=${shareUrl}`;
  popupWindow(url, popupOptions);
}

export function shareLinkToTwitter(
  shareUrl,
  shareTitle = null,
  pushData = null,
  shareCountUrl = null,
  popupOptions = null
) {
  if (pushData) {
    window.dataLayer.push(pushData);
  }
  const title = shareTitle || document.title;
  const url = `https://twitter.com/intent/tweet?hashtags=&original_referrer=&text=${encodeURI(
    title
  )}&url=${shareUrl}${shareCountUrl ? `&counturl=${shareCountUrl}` : ''}`;
  popupWindow(url, popupOptions);
}
